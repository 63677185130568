import './assets/main.css'

import * as Sentry from "@sentry/vue";

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { plugin, defaultConfig } from '@formkit/vue'
import config from '@/formkit.config'

import Toast from "vue-toastification";
import type { PluginOptions } from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
import App from './App.vue'
import router from './router'
import PrimeVue from 'primevue/config';
import ConfirmationService from 'primevue/confirmationservice';
import 'primevue/resources/themes/lara-light-teal/theme.css'
import Aura from '@primevue/themes/aura';

const app = createApp(App)

Sentry.init({
    app,
    dsn: import.meta.env.VITE_GLITCHTIP_DSN,
    integrations: [
        new Sentry.BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        }),
        new Sentry.Replay(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    environment: import.meta.env.VITE_ENVIRONMENT,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ["localhost", /^https:\/\/appwrite\.lpweb\.dev\/v1/],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
});

app.use(createPinia())
app.use(router)
app.use(plugin, defaultConfig(config))

app.use(PrimeVue, {
    theme: {
        preset: Aura
    }
});

const options: PluginOptions = {
    // You can set your default options here
};
app.use(Toast, options);

app.use(ConfirmationService);

app.mount('#app')
