<script setup lang="ts">
import { useHomeStore } from '@/stores/home'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import moment from 'moment'
import { FwbA } from 'flowbite-vue'

const store = useHomeStore()

const onPageGaranzie = (event: any) => {
  const page = event.page + 1
  store.garanziePage = page
  store.loadGaranzie()
}

const onPageContratti = (event: any) => {
  const page = event.page + 1
  store.contrattiPage = page
  store.loadContratti()
}
</script>

<template>
  <div class="space-y-4">

    <div>
      <h1 class="text-4xl font-bold dark:text-white mb-6">Garanzie in scadenza</h1>
      <DataTable
        :value="store.garanzieScadenza"
        paginator
        lazy
        :rows="30"
        tableStyle="min-width: 50rem"
        :totalRecords="store.garanzieTotalItems"
        @page="onPageGaranzie($event)"
        :loading="store.loadingGaranzia"
        stripedRows
        dataKey="id"
      >
        <Column field="cliente.proponente" header="Proponente" />
        <Column field="cliente.fiscalCode" header="Codice fiscale" />
        <Column field="cliente.vatNumber" header="Partita IVA" />
        <Column field="impiantoAddress" header="Indirizzo" />
        <Column field="impiantoCity" header="Comune" />
        <Column field="impiantoProvince" header="Provincia" />
        <Column header="Scadenza garanzia">
          <template #body="slotProps">
            {{ moment(slotProps.data.scadenzaGaranzia).format('DD/MM/YYYY') }}
          </template>
        </Column>
        <Column>
          <template #body="slotProps">
            <fwb-a :href="'/impianto/detail/' + slotProps.data.uuid">
              Dettaglio
            </fwb-a>
          </template>
        </Column>
      </DataTable>
    </div>

    <div>
      <h1 class="text-4xl font-bold dark:text-white mb-6">Contratti in scadenza</h1>
      <DataTable
        :value="store.contrattiScadenza"
        paginator
        lazy
        :rows="30"
        tableStyle="min-width: 50rem"
        :totalRecords="store.contrattiTotalItems"
        :loading="store.loadingContratti"
        @page="onPageContratti($event)"
        stripedRows
        dataKey="id"
      >
        <Column field="cliente.proponente" header="Proponente" />
        <Column field="cliente.fiscalCode" header="Codice fiscale" />
        <Column field="cliente.vatNumber" header="Partita IVA" />
        <Column field="impiantoAddress" header="Indirizzo" />
        <Column field="impiantoCity" header="Comune" />
        <Column field="impiantoProvince" header="Provincia" />
        <Column header="Scadenza assistenza">
          <template #body="slotProps">
            {{ moment(slotProps.data.scadenzaAssistenza).format('DD/MM/YYYY') }}
          </template>
        </Column>
        <Column>
          <template #body="slotProps">
            <fwb-a :href="'/impianto/detail/' + slotProps.data.uuid">
              Dettaglio
            </fwb-a>
          </template>
        </Column>
      </DataTable>
    </div>

  </div>
</template>
