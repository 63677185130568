import { defineStore } from 'pinia'
import { ref } from 'vue'
import type { Ref } from 'vue'
import { axiosRequest } from '@/api/api'
import type { AxiosResponse } from 'axios'
import type { HomepageData } from '@/interfaces/util'
import type { Impianto } from '@/interfaces/impianto'
import moment from 'moment'


export const useHomeStore = defineStore('home', () => {
  const contrattiScadenza: Ref<Impianto[]> = ref([])
  const garanzieScadenza: Ref<Impianto[]> = ref([])

  const contrattiTotalItems = ref(null)
  const garanzieTotalItems = ref(null)

  const contrattiPage = ref(1)
  const garanziePage = ref(1)

  const loadingGaranzia = ref(false)
  const loadingContratti = ref(false)

  const loadContratti = async () => {
    loadingContratti.value = true
    const date = moment().add(1, 'month').format('YYYY-MM-DD')
    const response: AxiosResponse<HomepageData> = await axiosRequest(
      'GET',
      `/api/impiantos?page=1&contrattoAssistenza=true&scadenzaAssistenza%5Bstrictly_before%5D=${date}&page=${contrattiPage.value}`,
      undefined,
      undefined,
      true
    )

    contrattiScadenza.value = response.data['hydra:member']
    contrattiTotalItems.value = response.data['hydra:totalItems']
    loadingContratti.value = false
  }

  const loadGaranzie = async () => {
    loadingGaranzia.value = true
    const date = moment().add(1, 'month').format('YYYY-MM-DD')
    const response: AxiosResponse<HomepageData> = await axiosRequest(
      'GET',
      `/api/impiantos?page=1&contrattoAssistenza=false&scadenzaGaranzia%5Bstrictly_before%5D=${date}&page=${garanziePage.value}`,
      undefined,
      undefined,
      true
    )

    garanzieScadenza.value = response.data['hydra:member']
    garanzieTotalItems.value = response.data['hydra:totalItems']
    loadingGaranzia.value = false
  }

  loadContratti()
  loadGaranzie()

  return {
    contrattiScadenza,
    garanzieScadenza,
    contrattiPage,
    garanziePage,
    contrattiTotalItems,
    garanzieTotalItems,
    loadContratti,
    loadGaranzie,
    loadingGaranzia,
    loadingContratti
  }
})
